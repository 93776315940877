<template>
  <v-dialog v-model="dialogArrived" persistent max-width="400px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-truck-fast</v-icon
                  >{{ $vuetify.lang.t("$vuetify.actualArrival") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
                <v-col class="py-0" cols="12" lg="12" md="4" sm="6">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  min-width="auto"
                  class="ma-0 pa-0"
                >
                  <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                    <v-text-field
                      append-icon="mdi-calendar"
                      :label="$vuetify.lang.t('$vuetify.plannedArrival')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="myObj.real_receive"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="myObj.real_receive"
                    @input="menu1 = false"
                    class="ma-0 pa-0"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.Confirm")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ViewArrived",
  props: { dialogArrived: Boolean , myObj: Object},
  data() {
    return {};
  },
  methods:{
      cancelForm() {
      this.$emit("update:dialogArrived", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    validate(){
        this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj)
        : console.log("false");
    }
  }
};
</script>